/* You can add global styles to this file, and also import other style files */
html, body {
    font-family: Roboto, Arial;
    height: 100%;
    margin: 0;
    padding: 0;
}

.dot {
    border: 1px dotted red;
}

.ac {
    text-align: center;
}

.gridheading {
    font-size: 22px;
    font-weight: 700;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}